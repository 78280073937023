<template>
  
  <template v-if="shop">
  <v-app-bar color="#fdfbe4" :elevation="2" title="" scroll-behavior="elevate">
    <v-app-bar-nav-icon>
      <v-img v-if="shop.shop_image" contain class="mx-2" max-width="40" min-width="40" :src="getImage(shop.shop_image)" alt="icon"></v-img>
    </v-app-bar-nav-icon>
    <v-app-bar-title>{{ shop.shop_name }}</v-app-bar-title>
  </v-app-bar>
  </template>
  <v-main>
    <v-container class="pa-0">

      <v-window disabled v-model="pageTab">

        <v-window-item value="1">
          <v-col v-if="options" cols="12">
            <v-card title="メニュー" subtitle="髪の長さで施術時間が変わります" class="" elevation="2">
              <v-card-text>

                <v-radio-group v-model="currentOption" inline >
                  <template v-for="option in options" :key="option">
                    <v-radio :label="option.title" :value="option.id"></v-radio>
                  </template>
                </v-radio-group>

                <!-- <p class="mb-2">{{ price }}</p> -->

                <v-select @update:menu="updateSelect()" v-model="currentSelect" :items="options[currentOption].menus" :item-title="name" label="メニュー" chips multiple >
                  <template v-slot:item="{ props, item }">
                    <v-list-item class="w-100" @click="clickMenu(item.raw)" v-bind="props" :subtitle="item.raw.subtitle" :selectable="false"></v-list-item>
                  </template>
                </v-select>
            
              </v-card-text>
            </v-card>
            
          </v-col>

          <v-col v-if="nominations" cols="12">
            <v-card title="指名" :subtitle="nomiName" class="" elevation="2">
              <v-card-text>
                <v-list>
                  <template v-for="nomination in nominations" :key="nomination">
                    <Nomination @clickList="clickList(nomination.id)" :nomination="nomination" />
                  </template>
                </v-list>
              </v-card-text>
            
            </v-card>
          </v-col>

          <v-col cols="12" v-if="dates.length && times.length">
            <v-card title="空き状況" subtitle="メニュー、指名により変動します" class="" elevation="2">
              <v-card-text>
                <div class="w-100 d-flex flex-row" style="overflow-x: scroll;text-align: center;border-bottom: 1px solid #c6c6c6;">
                  <div class="day-container sticky">
                    <div class="" style="border-bottom: 1px solid #8b8b8b;"></div>
                    <div v-for="time in times" :key="time" :class="getStyle(time.isDash)">
                      <p>{{ time.time }}</p>
                    </div>
                  </div>

                  <div class="day-container" v-for="date in dates" :key="date" :style="getRestBack(date.isRest)">
                    <div class="" style="border-bottom:1px solid #8b8b8b;">
                      <p>{{ date.date }}</p>
                      <p :style="getRestColor(date.week)">{{ date.week }}</p>
                    </div>
                    <div v-for="time,row in times" :key="date.time+time.time+currentNomination" @click="clickReserve(date,time.time)" :class="getStyle(time.isDash)" :style="getMyBack(date,row)">
                      <p>{{ getReserve(date.time_stamp,time.time,row) }}</p>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            
          </v-col>

        </v-window-item>

        <v-window-item value="2">
          <v-col v-if="options" cols="12">
            <v-card title="履歴" elevation="2">
              <v-card-text>
                <v-list>
                  <template v-for="history in historys" :key="history">
                    <History :history="history" @click="clickHist(history)"/>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-window-item>

      </v-window>
      
    </v-container>
  </v-main>


    <v-bottom-navigation v-model="selectBottom" color="teal" grow >
      <v-tabs v-model="pageTab" >
        <v-tab value="1"><v-icon>mdi-calendar-blank</v-icon>予約</v-tab>
        <v-tab value="2" @click="getHistory()"><v-icon>mdi-history</v-icon>履歴</v-tab>
      </v-tabs>
      <!--<v-btn @click="pageTab = 'reserve'">
        <v-icon>mdi-calendar-blank</v-icon>
        予約
      </v-btn>
      <v-btn @click="pageTab = 'history'">
        <v-icon>mdi-history</v-icon>
        理歴
      </v-btn>-->
    </v-bottom-navigation>

  <template>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            確認
          </v-card-title>
          <v-card-text style="white-space: pre-wrap;">{{ reserveTitle }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false" >
              キャンセル
            </v-btn>
            <v-btn color="green darken-1" text @click="doneReserve()" >
              予約する
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

  <template>
    <v-row justify="center">
      <v-dialog v-model="cancelDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            確認
          </v-card-title>
          <v-card-text style="white-space: pre-wrap;">{{ cancelTitle }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="cancelDialog = false" >
              そのまま
            </v-btn>
            <v-btn color="green darken-1" text @click="doneCancel()" >
              キャンセルする
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

  <template>
    <v-row justify="center">
      <v-dialog v-model="alertDialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
          </v-card-title>
          <v-card-text style="white-space: pre-wrap;">{{ alertTitle }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="alertDialog = false" >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  
</template>

<script>
import Nomination from "@/components/Nomination.vue"
import History from "./History.vue";
import liff  from "@line/liff";
import axios from "axios";

export default {
  name: 'Reservation-',
  components: {
    Nomination,
    History,
  },
  data: () => ({
    isNowProcess:false,
    shopId:0,

    pageTab:"1",

    alertDialog:false,
    cancelDialog:false,
    dialog:false,

    alertTitle:"",
    cancelTitle:"",
    reserveTitle:"",
    reserve:{
      date:null,
      time:null,
    },

    cancelHistory:null,
    
    nomiName:"",
    selectBottom:0,
    menu:"0",
    price:"",
    times:[],
    dates:[],

    currentOption:"0",
    currentSelect:[],
    currentNomination:0,
    currentPrice:0,
    currentTime:0,

    enableRserve:false,
    shop:null,
    options:null,
    nominations:null,
    rsvs:null,
    myRsvs:null,

    isFetchHistory:false,
    historys:null,

    userImg:"",
    userId:"",
    userName: "",
    isInClient: "",
    isLoggedIn: false,
  }),
  watch:{
    /*currentSelect:function(){
      
    },*/
    currentOption:function(){

      var pr = 0;
      var time = 0;

      if(this.currentSelect.length > 0){
        var disables = [];
        for(let n=0;n<this.currentSelect.length;n++){
          let obj = this.options[this.currentOption].menus.filter(object => object.title === this.currentSelect[n]);
          
          if(!obj.enable){
            disables.push(obj);
          }
        }

        console.log(disables);

        if(disables.length > 0){
          for(let n=0;n<disables.length;n++){
            this.currentSelect = this.currentSelect.filter(cu => cu !== disables[n].title);
          }
        }

        for(var n=0;n<this.currentSelect.length;n++){
          let obj = this.options[this.currentOption].menus.filter(object => object.title === this.currentSelect[n]);
          if(obj){
            console.log(obj[0]);
            pr += obj[0].price;
            time += obj[0].time;
          }
        }
        
        this.price = "¥"+pr+" "+time+"時間";
      }
      else{
        this.price = "";
      }

      this.currentPrice = pr;
      this.currentTime = time;

      console.log(this.currentPrice);
    },
  },
  created:function(){

    liff.init({
        liffId: process.env.VUE_APP_LIFF_ID
      })
      .then(() => {
        this.isLoggedIn = liff.isLoggedIn();
        if (!liff.isInClient() && !liff.isLoggedIn()) {
          const queryString = decodeURIComponent(window.location.search);
          const queries = new URLSearchParams(queryString);

          var sh = queries.get("shop");

          liff.login({
            redirectUri: process.env.VUE_APP_DOMAIN+"?shop="+sh
          });
        } 
        else {
          this.isInClient = liff.isInClient();
          liff.getProfile()
            .then(profile => {
              this.userId = profile.userId;
              this.userName = profile.displayName;
              this.userImg = profile.pictureUrl;
              //console.log(profile);
              const queryString = decodeURIComponent(window.location.search);
              const queries = new URLSearchParams(queryString);

              this.shopId = queries.get("shop");
              this.getIndex(false);
            });
        }
    })

    /*this.userId = "U8078da5d44d8fab931b50f6cb2906f54";//+new Date();
    this.userName = "木下歩";
    this.userImg = "https://profile.line-scdn.net/0hr7YtRZcjLRh0DT142eFTZwRdLnJXfHQKUDhmdxRYey9OOWNICGxqLUddICoaPGhPDzlje0kKJiB4Hlp-alvRLHM9cClIOGlPWGlj9g";
    const queryString = decodeURIComponent(window.location.search);
    const queries = new URLSearchParams(queryString);

    this.shopId = queries.get("shop");

    this.getIndex(false);*/
  
  },
  methods:{
    getIndex(isReload){
      var params = new URLSearchParams();
			params.append("shop_id", this.shopId);
      params.append("user_id",this.userId);
							
			axios.post(process.env.VUE_APP_POST_URL+"get_index.php",params)
				.then(response => {

          const res = response.data;
          console.log(res);
          if(res){
            if(!isReload){
              this.shop = res.shop;
              this.options = res.options;
              this.currentOption = Object.entries(this.options)[0][1].id;

              this.nominations = res.nominations;

              let nomiKey = Object.entries(this.nominations)[0][0];
              this.nominations[nomiKey].isSelect = true;
              this.nomiName = this.nominations[nomiKey].name;
              this.currentNomination = this.nominations[nomiKey].id;
            }

            this.enableRserve = res.enable_reserve;
            this.dates = res.dates;
            this.times = res.times;
            this.rsvs = res.rsvs;
            this.myRsvs = res.my_rsvs;
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    getHistory(){
      if(this.isFetchHistory)return;

      this.isFetchHistory = true;

      var params = new URLSearchParams();
			params.append("shop_id", this.shopId);
      params.append("user_id",this.userId);
							
			axios.post(process.env.VUE_APP_POST_URL+"get_history.php",params)
				.then(response => {

          const res = response.data;
          console.log(res);
          if(res){
            this.historys = res;
          }
        })
        .catch(error => {
          console.log(error);
        });

    },
    getImage(path){
      if(path){
        return process.env.VUE_APP_DOMAIN+path;
      }
      return "";
    },
    getReserve(date,time,row){
      
      var dt = this.dates.filter(object => object.time_stamp === date)[0];
      if(dt.isRest)return "";

      if(this.myRsvs[date]){
        if(this.myRsvs[date].indexOf(row) != -1){
          return "";
        }
      }

      if(this.rsvs[date][time][-1] != undefined){
        const rsvRest = this.rsvs[date][time][-1];
        if(rsvRest.time == -1){
          return "✖️";
        }
        else{
          if(rsvRest.time >= this.currentTime){
            if(date == "2024-08-28" && time == "18:00"){
              console.log("call");
            }
            return rsvRest.is_tri ? "🔺" : "⭕️";
          }
          else{
            return "";
          }
        }
      }

      var nomiId = this.currentNomination;
      if(this.shopId == "1"){
        if(this.nominations[nomiId].is_anyone && this.currentOption != 1){
          nomiId = 3;
        }
      }
      
      const rsv = this.rsvs[date][time][nomiId];
      if(rsv == undefined){
        return "";
      }

      //return rsv.time;

      if(rsv.time == -1){
        return "✖️";
      }
      else{
        if(rsv.time >= this.currentTime){
          
          return rsv.is_tri ? "🔺" : "⭕️";
        }
        else{
          return "";
        }
      }
    },
    clickReserve(date,time){

      if(!this.enableRserve){
        this.alertTitle = "すでに予約があります\n履歴より確認・変更ができます";
        this.alertDialog = true;
        return;
      }

      var dt = this.dates.filter(object => object.time_stamp === date.time_stamp)[0];
      if(dt.isRest)return;


      if(this.rsvs[date.time_stamp][time][-1] != undefined){
        const rsvRest = this.rsvs[date.time_stamp][time][-1];
        if(rsvRest.time == -1){
          return;
        }
        else{
          if(rsvRest.time < this.currentTime){
            return;
          }
        }
      }

      var nomiId = this.currentNomination;
      if(this.shopId == "1"){
        if(this.nominations[this.currentNomination].is_anyone && this.currentOption != 1){
          nomiId = 3;
        }
      }

      const rsv = this.rsvs[date.time_stamp][time][nomiId];
      if(rsv == undefined){
        return;
      }

      if(rsv.time >= this.currentTime){

        if(this.currentSelect.length == 0){
          this.alertTitle = "メニューを選択してください";
          this.alertDialog = true;
        }
        else{
          this.reserveTitle = date.date+date.week+" "+time+"\n予約しますか?";
          this.reserve.date = date;
          this.reserve.time = time;
          this.dialog = true;
        }
      }

      
    },
    doneReserve(){
      this.dialog = false;
      this.sendReserve(this.reserve.date,this.reserve.time);
    },
    sendReserve(date,time){

      if(this.isNowProcess)return;
      this.isNowProcess = true;

      var nomiId = this.currentNomination;
      var name = this.nomiName;
      if(this.shopId == "1"){
        if(this.nominations[this.currentNomination].is_anyone && this.currentOption != 1){
          nomiId = 3;
          name = this.nominations[nomiId].name;
        }
      }

      var params = new URLSearchParams();
      params.append("user_id",this.userId);
      params.append("user_name",this.userName);
      params.append("user_icon",this.userImg);

			params.append("shop_id",this.shop.id);
      params.append("date", date.time_stamp);
      params.append("time", time);
      params.append("nomination", nomiId);
      params.append("option",this.currentOption);
      params.append("price",this.currentPrice);
      
      var ext = 0;
      var range = 0;
      if(this.currentSelect.length > 0){
        for(var n=0;n<this.currentSelect.length;n++){
          let obj = this.options[this.currentOption].menus.filter(object => object.title === this.currentSelect[n]);
          if(obj){
            ext += obj[0].extension;
            range += obj[0].time;
          }
        }
      }

      params.append("range",range);
      params.append("extension",ext);
      params.append("menuNames",JSON.stringify(this.currentSelect))

							
			axios.post(process.env.VUE_APP_POST_URL+"set_reserve.php",params)
				.then(response => {

          const res = response.data;
          console.log(res);
          if(res){
            if(res.result){
              var menus = "";
              if(this.currentSelect.length > 0){
                for(let n=0;n<this.currentSelect.length;n++){
                  if(n != 0)menus += ",";
                  menus += this.currentSelect[n];
                }
              }

              var str = "予約番号:"+res.id+"\n"+date.date+date.week+" "+time+"\n担当:"+name+"\n"+menus;
              this.alertTitle = "予約完了\n\n"+str+"\n\nキャンセルする際は履歴から行ってください";
              this.alertDialog = true;

              if(this.isFetchHistory){
                this.historys.unshift(res.history);
              }
              
              this.isNowProcess = false;
              this.sendMessage(str);
              this.getIndex(true);
            }
            else{
              this.alertTitle = res.msg;
              this.alertDialog = true;
              this.isNowProcess = false;
              this.getIndex(true);
            }
          }
        })
        .catch(error => {
          this.alertTitle = "通信エラー";
          this.alertDialog = true;
          this.isNowProcess = false;
          console.log(error);
        });
    },
    clickList(id){
      this.currentNomination = id;
      for(let key in this.nominations){
        this.nominations[key].isSelect = false;
      }

      this.nominations[id].isSelect = true;
      this.nomiName = this.nominations[id].name;
    },
    clickMenu(obj){
      console.log(obj);
      if(!obj.enable){
        this.alertTitle = obj.memo;
        this.alertDialog = true;
      }
    },
    updateSelect(){

      var pr = 0;
      var time = 0;

      if(this.currentSelect.length > 0){

        var disables = [];

        for(let n=0;n<this.currentSelect.length;n++){
          let obj = this.options[this.currentOption].menus.filter(object => object.title === this.currentSelect[n]);
          
          if(obj[0].enable == false){
            disables.push(obj);
          }
        }

        if(disables.length > 0){
          console.log("disa");
          console.log(disables);
          for(let n=0;n<disables.length;n++){
            console.log(disables[n][0].title);
            this.currentSelect = this.currentSelect.filter(cu => cu != disables[n][0].title);
          }
          console.log("select");
          console.log(this.currentSelect);
        }

        for(let n=0;n<this.currentSelect.length;n++){
          let obj = this.options[this.currentOption].menus.filter(object => object.title === this.currentSelect[n]);

          if(obj){
            //console.log(obj[0]);
            pr += obj[0].price;
            time += obj[0].time;
          }
        }

        this.price = "¥"+pr+" "+time+"時間";
      }
      else{
        this.price = "";
      }

      this.currentPrice = pr;
      this.currentTime = time;
    },
    sendMessage(str){
      liff.sendMessages([
        {
          type: "text",
          text: str,
        },
      ])
      .then(() => {
        console.log("message sent");
      })
      .catch((err) => {
        console.log("error", err);
        var params = new URLSearchParams();
        params.append("shop_id", this.shopId);
        params.append("str",str);

        axios.post(process.env.VUE_APP_POST_URL+"send_mail.php",params)
				.then(response => {
          const res = response.data;
          console.log(res);
        })
        .catch(error => {
          this.isNowProcess = false;
          console.log(error);
        });
      });
    },
    clickHist(history){
      if(!history.isEnd){
        this.cancelHistory = history;
        this.cancelTitle = history.date+"\nキャンセルしますか?";
        this.cancelDialog = true;
      }
    },
    doneCancel(){
      this.cancelDialog = false;

      if(this.isNowProcess)return;
      this.isNowProcess = true;

      var params = new URLSearchParams();
			params.append("shop_id", this.shopId);
      params.append("hist_id",this.cancelHistory.id);
      params.append("user_id",this.userId);
							
			axios.post(process.env.VUE_APP_POST_URL+"cancel_history.php",params)
				.then(response => {

          this.isNowProcess = false;

          const res = response.data;
          console.log(res);
          if(res.result){
            this.historys = this.historys.filter(n => n.id !== this.cancelHistory.id);

            this.alertTitle = "キャンセルしました"
            this.alertDialog = true;
            this.getIndex(true);
          }
          else{
            this.alertTitle = "エラー\nチャットメッセージよりお問い合わせください"
            this.alertDialog = true;
          }
        })
        .catch(error => {
          this.isNowProcess = false;
          console.log(error);
        });

    },
  },
  computed:{
    getStyle(){
      return (border) => {
        if(border)return "border-mid";
        else return "border-bot";
      };
    },
    getRestBack(){
      return (isRest) => {
        if(isRest)return {"background-color":"#dfdfdf"};
        else return {"background-color":"clear"};
      };
    },
    getRestColor(){
      return (week) => {
        if(week == "(土)" || week == "(日)")return {"color":"red"};
        else return {"color":"black"};
      };
    },
    getMyBack(){
      return (date,row) => {
        if(!date.isRest && this.myRsvs[date.time_stamp]){
          if(this.myRsvs[date.time_stamp].indexOf(row) != -1){
            return {"background-color":"#cceae7"};
          }
        }
        else{
          return {"background-color":"none"};
        }
      };
    },
  }
}
</script>

<style scoped>

.day-container{
  border-right:1px solid #c6c6c6;
}

.day-container div{
  /*padding: 14px 0;*/
  height: 50px;
  width:50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.day-container div:nth-child(2){
  border-top:none;
}

.border-bot{
  border-top:1px solid #8b8b8b;
}

.border-mid{
  border-top:1px dashed #b2b2b2;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  border-top: none;
  border-bottom: none;
  background-color: white;
}
.sticky:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

</style>